@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

h1,
h2 {
  font-family: "museo", serif;
}

/* SAMPLE USAGE */
/* font-family: "museo", serif;
font-weight: 500;
font-style: normal;

font-family: "museo", serif;
font-weight: 700;
font-style: normal; */

.border-1 {
  border-width: 1px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.fade-in {
  animation: fadeIn ease 1s;
  animation-fill-mode: forwards;
}

.fade-out {
  animation: fadeOut ease 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}